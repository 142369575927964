import React, { useState } from 'react'
import { DialogTitleWithBtn } from 'shared/ui/components'
import { Autocomplete, Button, DialogActions, FormControlLabel, MenuItem, Radio, RadioGroup } from '@mui/material'
import icon from 'assets/images/icons/excel.svg'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import { groupByDateOptions, statsOptions, unitsOptions, viewOptions } from './options'

import { StyledExportModalContainer } from '../../styled'

type unitsType = 'price' | 'incomingPrice'
type groupByType = 'day' | 'month' | 'quarter' | 'year' | 'week'
type statsType = 'orders' | 'sales' | 'stocks'
type viewType = 'currency' | 'items' | 'all'

interface ISelectedParams {
  view: viewType
  units: unitsType
  groupby: groupByType
  stats: statsType
}

export const FileExportModal = ({ close, accept, unloadingSettings }) => {
  const [ selectedParams, setSelectedParams ] = useState<ISelectedParams>({
    view: unloadingSettings.view, groupby: unloadingSettings.groupby, stats: unloadingSettings.stats, units: 'incomingPrice'
  })
  
  return (
    <StyledExportModalContainer>
      <DialogTitleWithBtn
        sx={ { fontSize: '20px', padding: '0px' } }
        onClose={ close }>
        Параметры выгрузки
      </DialogTitleWithBtn>

      <div className="radio-group-container">

        <Autocomplete
          id="stats"
          value={statsOptions.find(el => el.value === selectedParams.stats)}
          onChange={(_, value) =>
            setSelectedParams(prev => ({
              ...prev,
              stats: value?.value as statsType,
              groupby: value?.value === 'stocks' ? 'day' : prev.groupby
            }))}
          size="small"
          disableClearable={true}
          blurOnSelect={true}
          options={statsOptions}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Тип выгрузки"
            />
          }
        />

        <Autocomplete
          id="stats"
          value={viewOptions.find(el => el.value === selectedParams.view)}
          onChange={(_, value) => setSelectedParams(prev => ({ ...prev, view: value?.value as viewType }))}
          size="small"
          disableClearable={true}
          blurOnSelect={true}
          options={viewOptions}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Единицы измерения"
            />
          }
        />

        <Autocomplete
          id="units"
          disabled={selectedParams.view === 'items'}
          value={unitsOptions.find(el => el.value === selectedParams.units)}
          onChange={(_, value) => setSelectedParams(prev => ({ ...prev, units: value?.value as unitsType }))}
          size="small"
          disableClearable={true}
          blurOnSelect={true}
          options={unitsOptions}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Рубли"
            />
          }
        />

        <Autocomplete
          id="stats"
          disabled={selectedParams.stats === 'stocks'}
          value={groupByDateOptions.find(el => el.value === selectedParams.groupby)}
          onChange={(_, value) => setSelectedParams(prev => ({ ...prev, groupby: value?.value as groupByType }))}
          size="small"
          disableClearable={true}
          blurOnSelect={true}
          options={groupByDateOptions}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Детализация по"
            />
          }
        />

      </div>

      <DialogActions sx={ { padding: '0' } }>
        <Button
          variant="text"
          onClick={ close }
        >
          ОТМЕНА
        </Button>
        <Button
          variant="contained"
          onClick={ () => accept(selectedParams) }
          startIcon={ <ExcelIcon src={ icon } alt="excelImg" /> }
        >
          ЭКСПОРТ
        </Button>
      </DialogActions>
    </StyledExportModalContainer>
  )
}