import React from 'react'
import { pimApi } from 'shared/api'
import UpdateIcon from '@mui/icons-material/Update'
import { sort } from 'shared/lib/utils/sort'
import { getStocksDate } from 'entities/pim/stocks/ui'
import { StyledDiscount } from 'entities/pim/price/ui/styled'
import { round } from 'shared/lib/utils/round'
import { Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import styled from 'styled-components'
import { tooltipClasses } from '@mui/material/Tooltip'
import { HelpWithMargin } from 'shared/ui/styled/Help'

import { ProfitabilityContainer, StyledContainer } from './styled'

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    padding: '12px',
  },
}))

export const ProductsProfitability = ({ productId, price }: { productId: number, price: number}) => {
  const ProfitabilityData = pimApi.products.common.useProductsProfitabilityQuery({ price: Math.floor(price), productId, priority: true })

  const lastUpdateDate = (ProfitabilityData.data && ProfitabilityData.data.length !== 0) ?
    getStocksDate(sort(ProfitabilityData.data, 'update')[0].update) :
    ''

  const cloneProfitabilityData = [...ProfitabilityData?.data || []]
  const minMaxProfitability = {
    max: sort(cloneProfitabilityData || [], 'perProfitFbo')?.[0]?.perProfitFbo,
    min: sort(cloneProfitabilityData || [], 'perProfitFbo')?.[(ProfitabilityData?.data?.length || 0) - 1]?.perProfitFbo
  }

  const mainWarehouses = ProfitabilityData?.data?.slice(0, 2)
  const otherWarehouses = (ProfitabilityData.data && ProfitabilityData.data.length > 2) ? ProfitabilityData?.data?.slice(2) : []

  return (
    <>
      {(ProfitabilityData.data && ProfitabilityData.data.length !== 0) &&
      <StyledContainer>
        <div className="title-container">
          <span className="title">Доходность по складам</span>
          <div className="date-time-container">
            <UpdateIcon className="icon"/>
            <span className="date-time-text">{lastUpdateDate}</span>
          </div>
        </div>
        <ProfitabilityContainer>
          { mainWarehouses?.map(el => (
            <div className="warehouse-item" key={el.marketplacewarehouseName}>
              <span className="warehouse-name" title={el.marketplacewarehouseName}>{el.marketplacewarehouseName}</span>
              <div className="warehouse-values">
                <span className="profit-value">{round(el.profitFbo, 0)}</span>
                <StyledDiscount style={{ marginTop: '0' }}>{el.perProfitFbo}%</StyledDiscount>
              </div>
            </div>
          )) }
          {ProfitabilityData.data.length > 2 &&
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span style={{ fontSize: '14px' }}>Другие</span>
            <CustomTooltip title={
              <div style={{ width: '230px', height: '353px', overflowY: 'auto' }}>
                <ProfitabilityContainer>
                  {otherWarehouses.map((el) => (
                    <div className="warehouse-item" key={el.marketplacewarehouseName}>
                      <span className="warehouse-name" title={el.marketplacewarehouseName}>{el.marketplacewarehouseName}</span>
                      <div className="warehouse-values">
                        <span className="profit-value">{round(el.profitFbo, 0)}</span>
                        <StyledDiscount style={{ marginTop: '0', paddingTop: 0 }}>{el.perProfitFbo}%</StyledDiscount>
                      </div>
                    </div>
                  ))}
                </ProfitabilityContainer>
              </div>
            }
            placement="right">
              <HelpWithMargin style={{ marginLeft: '5px' }}>
                <InfoOutlined />
              </HelpWithMargin>
            </CustomTooltip>
          </div>
          }
        </ProfitabilityContainer>
        { ProfitabilityData.data.length > 1 &&
        <div className="min-max-percents-container">
          <span className="min-max-text">мин</span>
          <StyledDiscount style={{ marginTop: '0', marginLeft: '0' }}>{minMaxProfitability.min}%</StyledDiscount>
          <span className="min-max-text">–</span>
          <StyledDiscount style={{ marginTop: '0', marginLeft: '0' }}>{minMaxProfitability.max}%</StyledDiscount>
          <span className="min-max-text">макс</span>
        </div>
        }
      </StyledContainer>
      }
    </>
  )
}