import { Box, CircularProgress } from '@mui/material'
import styled from 'styled-components'
import LoadingButton from '@mui/lab/LoadingButton'

export const ChartsPaper = styled(Box)`
  box-shadow: none;
  padding: 28px 24px 0 24px;
  min-height: 537px;
  position: relative
`

export const StyledLoader = styled(CircularProgress)`
  position: relative;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const StyledProducts = styled(Box)`
  margin-top: 24px;
  margin-left: -24px;
  margin-right: -24px;
  
  .MuiPaper-root {
    box-shadow: none;
  }
  .products-header {
    display: none;
  }
  .column-settings {
    display: none;
  }
`

export const StyledChipsContainer = styled(Box)`
  padding-left: 276px;
  padding-right: 24px;
  div {
    margin-right: 16px;
    margin-bottom: 16px;
    :last-child {
      margin-right: 0;
    }
  }
`

export const FilterWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
  margin-top: 32px;
  padding: 0 24px 16px;
  
  .searchInput {
    color: rgba(0, 0, 0, 0.26);
    display: flex;
    min-width: 220px;
    margin-top: 5px;
    justify-content: flex-end;
    padding-bottom: 2px;
  }
`

export const StyledExport = styled(LoadingButton)`
  transition: 0.3s;
  margin-left: auto;
  height: 36px;
  min-width: 50px;
  .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }

  .button-span {
    display: none;
  }
    
  @media (min-width:1848px) {
    min-width: inherit;
      
    .MuiButton-startIcon {
      margin-right: 5px;
      margin-left: inherit;
    }

    .button-span {
      display: inherit;
    }
  }
`

export const StyledChartsHeader = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  margin-bottom: 16px;
  
  .title-text {
    @media (max-width:1945px) {
      display: none;
    }
    margin-right: 58px;
    font-size: 20px;
    font-weight: 400;
  }
  
  .date-picker-container {
    display: flex;
    margin-left: 24px;
    flex-direction: row;
    gap: 16px;
    
    >div {
      max-width: 180px;
      min-width: 135px;
    }
  }
  
  .units-chart-container {
    @media (max-width:1755px) {
      margin-left: 16px;
      gap: 8px;
    }
    margin-left: 48px;
    margin-right: 8px;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    
    button {
      white-space: nowrap;
    }
  }
  
  .export-button {
    margin-left: auto;
  }
`

export const StyledExportModalContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  .radio-group-container {
    display: grid;
    grid-template-columns: repeat(2, 290px);
    gap: 16px;
    
    >div {
      width: 290px;
    }
  }
`