import React, { useEffect, useMemo, useState } from 'react'
import Chart, {
  Series,
  Legend,
  CommonSeriesSettings,
  Point,
  Label,
  Tooltip,
  Tick,
  ArgumentAxis,
  ZoomAndPan,
  ValueAxis,
  Grid, Font, MinVisualRangeLength
} from 'devextreme-react/chart'
import RangeSelector, {
  Size,
  Chart as ChartOptions,
  Margin,
  Label as RangeLabel,
  Scale,
  Behavior,
  SliderMarker, MinorTick, Marker, MinRange
} from 'devextreme-react/range-selector'
import { Box, Typography } from '@mui/material'
import { DateAppearanceSettings } from 'shared/lib/utils/DateAppearanceSettings'
import { debouncedSearch } from 'shared/ui/components/Table/ui/TableSearch/TableSearch'
import { round } from 'shared/lib/utils/round'
import queryString from 'query-string'

import { ChartDetails } from './ChartDetails'
import { StyledDot, StyledLoader, StyledTooltip } from './styled'

import { useChartContext } from '../../../../lib/useChartContext'
import { mergeArrayByKey } from '../../../../../../../shared/lib/utils/mergeArrayByKey'

interface dataType {
  orders: number
  sales: number
  orderDate: string
}

export const Charts = ({ isLoading }: { isLoading: boolean }) => {
  const { value: context } = useChartContext()
  const { searchObj, ordersData, salesData } = context

  const [ rangeSelector, setRangeSelector ] =
    useState({
      startValue: searchObj.timelineStart,
      endValue: searchObj.timelineEnd
    })

  const handleChangeTimelineParams = (params) => {
    const historyStr = queryString.stringify(
      { ...searchObj, ...params },
      { skipEmptyString: true, skipNull: true, encode: true, arrayFormat: 'bracket' }
    )
    window.history.pushState({}, '', `?${historyStr}`)
  }

  useEffect(() => {
    setRangeSelector({ startValue: searchObj.timelineStart, endValue: searchObj.timelineEnd })
  },[searchObj.timelineStart, searchObj.timelineEnd])

  const timelineData: Array<dataType> | any = useMemo(() => 
    mergeArrayByKey('orderDate', ordersData?.data?.data, salesData?.data?.data.map((elem) => ({
      orderDate: elem.saleDate,
      quantity: elem.quantity
    }))).map((el) => ({
      orders: el[searchObj.units! === 'items' ? 'items' : 'currency'],
      sales: salesData?.data.data.filter((elem) => elem.saleDate === el.orderDate).reduce((sum, elem) => sum + elem.quantity, 0),
      orderDate:el.orderDate
    })), [ordersData, salesData])

  const rangeData: Array<dataType> | any = useMemo(() => timelineData.map((el) => ({
    orders: el.orders,
    sales: el.sales,
    orderDate: new Date(el.orderDate)
  }
  )), [ordersData, salesData])


  const [hiddenSeries , setHiddenSeries] = useState({ orders: true, sales: true })

  const updateVisualRange = (e) => {
    // во избежании перерисовки компонента rangeSelector идет проверка на изменение извне
    if (Object.keys(e)[0] !== '0') {
      const startValue = new Date(e.startValue)
      const endValue = new Date(e.endValue)
      const startValueString =
        new Date(startValue.getTime() + Math.abs(startValue.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      const endValueString = new Date(endValue.getTime() + Math.abs(endValue.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      debouncedSearch(() => handleChangeTimelineParams({
        timelineStart: startValueString,
        timelineEnd: endValueString,
      }))
      setRangeSelector(e)
    }
  }

  const renderTooltip = (props) => <CustomTooltip x={props}/>

  return (
    <>
      {isLoading && <StyledLoader size={60}/>}
      <ChartDetails 
        units={searchObj.units!} 
        setHiddenSeries={setHiddenSeries}
        visualRange={{
          startValue: rangeSelector.startValue || searchObj.startValue,
          endValue: rangeSelector.endValue || searchObj.endValue
        }}
        ordersData={ordersData?.data || { data: [], dateFrom: '', dateTo: '' }}
        salesData={salesData?.data || { data: [], dateFrom: '', dateTo: '' }}
        hiddenSeries={hiddenSeries} 
      />
      <Chart
        palette="Harmony Light"
        dataSource={timelineData}
        barGroupWidth={18}
      >
        <Series
          visible={hiddenSeries.orders}
          valueField="orders"
          type={searchObj.chartType === 'diagram' ? 'bar' : 'spline'}
          color="#7BC67E"
          name="Заказы">
          <Point hoverMode="none"/>
        </Series>
        <Series
          visible={hiddenSeries.sales}
          valueField="sales"
          axis="sales"
          type={searchObj.chartType === 'diagram' ? 'bar' : 'splinearea'}
          color="#bcdffb"
          name="Выкупы">
          <Point hoverMode="none"/>
        </Series>
        <ArgumentAxis
          color="#E0E0E0"
          argumentType="datetime"
          onVisualRangeChange={updateVisualRange}
          visualRange={rangeSelector}
          discreteAxisDivisionMode="crossLabels"
        >
          <MinVisualRangeLength days={1}/>
          <Label customizeText={customDate}/>
          <Tick color="#E0E0E0"/>
        </ArgumentAxis>
        <Margin top={24}/>
        <ValueAxis type="continuous" name="orders" showZero={false} endOnTick={true} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#4CAF50"/>
          </Label>
          <Grid visible={true} color="#E0E0E066"/>
        </ValueAxis>
        <Tooltip
          enabled={true}
          shared={true}
          interactive={false}
          cornerRadius={4}
          border={{ opacity: 0 }}
          color="#FFFFFF00"
          paddingLeftRight={0}
          paddingTopBottom={0}
          shadow={{ blur: 0, offsetY: 0, offsetX: 0, opacity: 0 }}
          contentRender={renderTooltip}
        />
        <ValueAxis type="continuous" position="right" name="sales" showZero={false} endOnTick={true} color="#E0E0E0">
          <Tick color="#E0E0E0"/>
          <Label customizeText={numberWithSpaces}>
            <Font color="#2196F3"/>
          </Label>
          <Grid visible={true} color="#E0E0E0"/>
        </ValueAxis>
        <Legend visible={false}/>
        <ZoomAndPan
          argumentAxis="zoom"
          allowTouchGestures={true}
          dragToZoom={true}
          allowMouseWheel={false}
          panKey="shift"/>
        <CommonSeriesSettings cornerRadius={2} aggregation={{ enabled: false }} argumentField="orderDate" hoverMode="none">
          <Point size={6}/>
        </CommonSeriesSettings>
      </Chart>

      <RangeSelector
        dataSource={rangeData}
        value={rangeSelector}
        onValueChange={updateVisualRange}
      >
        <Size height={100} />
        <Scale type="continuous" valueType="datetime" minorTickInterval="day">
          <MinorTick visible={false}/>
          <Marker visible={false}/>
          <MinRange days={1}/>
          <RangeLabel customizeText={customDate} />
        </Scale>
        <SliderMarker
          paddingTopBottom={4}
          paddingLeftRight={8}
          color="#F5F5F5"
          customizeText={customDate}>
          <Font color="rgba(0, 0, 0, 0.6)" size={11} weight={600}/>
        </SliderMarker>
        <Behavior callValueChanged={ searchObj.range !== 2 ? 'onMoving' : 'onMovingComplete'}/>
        <ChartOptions>
          <CommonSeriesSettings type="spline" />
          <Series visible={hiddenSeries.orders} argumentField="orderDate" valueField="orders" color="#4CAF50"/>
          <Series visible={hiddenSeries.sales} type="splinearea" argumentField="orderDate" valueField="sales" color="#BCDFFB"/>
          <ValueAxis/>
          <ValueAxis />
        </ChartOptions>
      </RangeSelector>
    </>
  )
}

const customDate = (x) => {
  if (x.value) {
    const utc = new Date(x.value)
    const stringDate = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
    return (
      `${stringDate.split('-')[2]} ${DateAppearanceSettings[stringDate.split('-')[1]].month}`
    )
  }
  return ''
}

const numberWithSpaces = (x) => x.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

const CustomTooltip = ({ x }) => {
  const utc = new Date(x.argumentText)
  const stringData = new Date(utc.getTime() + Math.abs(utc.getTimezoneOffset()*60000)).toISOString().split('T')[0]

  const orders = x.points.find(el => el.seriesName === 'Заказы')
  const sales = x.points.find(el => el.seriesName === 'Выкупы')

  return (
    <StyledTooltip>
      <Typography sx={{ fontSize: '12px', marginBottom: '12px' }}>{`
        ${stringData.split('-')[2]  }
        ${  DateAppearanceSettings[stringData.split('-')[1]].month},
        ${stringData.split('-')[0]}`}
      </Typography>
      {orders &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
          <StyledDot color="#4CAF50"/>
          <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Заказы</Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#4CAF50',
              marginLeft: 'auto'
            }}>
            {round(orders?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
        </Box>
      }
      {sales &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '26px' }}>
          <StyledDot color="#2196F3"/>
          <Typography sx={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}>Выкупы</Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#2196F3',
              marginLeft: 'auto'
            }}>
            {round(sales?.value, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Typography>
        </Box>
      }
    </StyledTooltip>
  )
}