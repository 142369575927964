/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AnalyticsExcelUnits, AnalyticsExcelView, Order, StatsSearchParams } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** An enumeration. */
    units: AnalyticsExcelUnits,
  /** An enumeration. */
    view: AnalyticsExcelView,
  /**
   * Date From
   * Дата, начиная с которой возвращается статистика
   * @format date
   */
    date_from?: string,
  /**
   * Date To
   * Дата, по которую возвращается статистика
   * @format date
   */
    date_to: string,
  /**
   * Format
   * Формат файла для экспорта
   * @default "excel"
   */
    format?: "excel",
  /**
   * Sort
   * Столбец сортировки
   */
    sort?: string,
  /**
   * Order
   * Порядок сортировки ASC/DESC
   */
    order?: "ASC" | "DESC",
  /**
   * Groupby
   * Интервал группировки
   */
    groupby?: "day" | "month" | "quarter" | "year" | "week",

},
  data: StatsSearchParams
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post(`/analytics/file`,data, { params: query, responseType: 'blob' })


export const useexportToFileFilePost = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

