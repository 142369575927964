import React, { useState } from 'react'
import { ButtonGroup } from '@mui/material'
import { DatePicker } from 'shared/ui/components'
import { StyledButtonGroup } from 'shared/ui/styled/StyledButtonGroup'
import { subtractMonths } from 'shared/lib/utils/subtractMonths'
import { getPrevDay } from 'shared/lib/utils/getPrevDay'
import { subtractDays } from 'shared/lib/utils/subtractDays'
import { downloadFile } from 'shared/lib/utils'
import { snackActions } from 'shared/lib/react/snackbar'
import queryString from 'query-string'
import { StyledChartsHeader } from 'features/analytics/chart/styled'
import { checkValue } from 'shared/lib/checkers'
import { analyticsGen } from 'shared/lib/generated'
import { dialogModel } from 'shared/ui/components/dialog'
import LoadingButton from '@mui/lab/LoadingButton'
import { ExcelIcon } from 'shared/ui/styled/ExcelIcon'
import icon from 'assets/images/icons/excel.svg'

import { FileExportModal } from '../../../modal/FileExportModal'
import { useChartContext } from '../../../../lib/useChartContext'

enum Interval { Week, Month, Year }
enum Units { Items, Price, IncomingPrice }

export const Header = () => {
  const { value } = useChartContext()
  const { searchObj, handleChartParams, ordersData } = value

  const { mutate: downloadMutation, isLoading } = analyticsGen.analytics.exportToFileFilePost.useexportToFileFilePost()

  const findRange = () => {
    switch (searchObj.range) {
      case 0:
        return 'week'
      case 1:
        return 'month'

      case 2: 
        return 'year'
    
      default:
        return true
    }
  }

  const [groupbyJustifier, setGroupbyJustifier] = useState(findRange())


  const handleExport = () => {
    const historySearch: any = queryString.parse(window.location.search, { arrayFormat: 'bracket', parseNumbers: true })
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <FileExportModal close={close} accept={accept} unloadingSettings={{
          view: searchObj.units,
          groupby: groupbyJustifier,
          stats: 'orders'
        }}/>
      ),
      onAccept: ({ units, groupby, stats, view }) => {
        downloadMutation({
          query: {
            view,
            units,
            groupby,
            dateFrom: historySearch.timelineStart,
            dateTo: historySearch.timelineEnd,
            format: 'excel',
            sort: searchObj.sort,
            order: searchObj.order ? searchObj.order.toUpperCase() as any : undefined
          },
          data: {
            ...searchObj as any,
            stats: [stats],
            order: searchObj.order ? searchObj.order.toUpperCase() as any : undefined,
            orderStatus: checkValue(searchObj?.orderStatus?.[0]),
            orderStatusInterval: checkValue(searchObj?.orderStatusInterval?.[0]),
          }
        }, {
          onSuccess: (response) => {
            const file = response.data
            const fileName =
              decodeURIComponent(response.headers['content-disposition']?.replace(
                'inline; filename=',
                ''
              ))
            downloadFile(file, fileName)
          },
          onError: () => {
            snackActions.error('Произошла ошибка при загрузке файла')
          }
        })
        // downloadMutation({
        //   ...searchObj,
        //   orderStatus: searchObj?.orderStatus?.[0],
        //   orderStatusInterval: searchObj?.orderStatusInterval?.[0],
        //   groupby: granularity,
        //   date_from: historySearch.timelineStart,
        //   date_to: historySearch.timelineEnd,
        //   stocks_status: searchObj?.stocksStatus?.[0],
        //   auto_ordering: searchObj?.autoOrdering?.[0],
        //   stats },  {
        // })
      }
    })
  }

  const rangeButtonClick = (n : Interval) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        handleChartParams({
          timelineStart: subtractDays(new Date(), 7),
          timelineEnd: getPrevDay(),
          range: n,
        })
        setGroupbyJustifier('week')
        return null
      }
      case 1: {
        handleChartParams({
          timelineStart: subtractMonths(new Date(), 1),
          timelineEnd: getPrevDay(),
          range: n,
        })
        setGroupbyJustifier('month')
        return null
      }
      case 2: {
        handleChartParams({
          timelineStart: subtractMonths(new Date(), 12),
          timelineEnd: getPrevDay(),
          range: n
        })
        setGroupbyJustifier('year')
        return null
      }
    }
    return true
  }

  const unitsButtonClick = (n: Units) => {
    // eslint-disable-next-line default-case
    switch (n) {
      case 0: {
        return handleChartParams({
          units: 'items',
        })
      }
      case 1: {
        return handleChartParams({
          units: 'price',
        })
      }
      case 2: {
        return handleChartParams({
          units: 'incomingPrice',
        })
      }
    }
    return true
  }

  return (
    <StyledChartsHeader>

      <span className="title-text">
        Заказы и остатки
      </span>

      <ButtonGroup size="large" variant="text" aria-label="text button group">
        <StyledButtonGroup $active={searchObj.range === 0} onClick={() => rangeButtonClick(Interval.Week)}>Неделя</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 1} onClick={() => rangeButtonClick(Interval.Month)}>Месяц</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 2} onClick={() => rangeButtonClick(Interval.Year)}>Год</StyledButtonGroup>
        <StyledButtonGroup $active={searchObj.range === 3} $isOtherButton={true}>Другое</StyledButtonGroup>
      </ButtonGroup>


      <div className="date-picker-container">
        <DatePicker
          name="dateFrom"
          label="Начало периода"
          views={['day']}
          inputHeight={36}
          showClear={false}
          disabled={!ordersData?.data.dateFrom}
          value={(searchObj.range === 2) ? ordersData?.data.dateFrom : searchObj.startValue}
          onChange={(e) => { if (e) handleChartParams({
            timelineStart: new Date(e).toISOString().split('T')[0],
            startValue: new Date(e).toISOString().split('T')[0],
            range: 3
          })}}
          onBlur={(e) => {
            if (e.target.value) {
              const [day, month, year] = e.target.value.split('.')
              if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.data.dateFrom) {
                handleChartParams({
                  startValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  timelineStart: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  range: 3
                })
              }}
          }}
        />
        <DatePicker
          name="dateTo"
          label="Конец периода"
          views={['day']}
          inputHeight={36}
          showClear={false}
          disabled={!ordersData?.data.dateTo}
          value={(searchObj.range === 2) ? ordersData?.data.dateTo : searchObj.endValue}
          onChange={(e) => {if (e) handleChartParams({
            endValue: new Date(e).toISOString().split('T')[0],
            timelineEnd: new Date(e).toISOString().split('T')[0],
            range: 3
          })}}
          onBlur={(e) => {
            if (e.target.value) {
              const [day, month, year] = e.target.value.split('.')
              if (new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0] !== ordersData?.data.dateTo) {
                handleChartParams({
                  endValue: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  timelineEnd: new Date(Number(year), Number(month) - 1, Number(day) + 1).toISOString().split('T')[0],
                  range: 3
                })
              }}
          }}
        />
      </div>

      <div className="units-chart-container">
        <ButtonGroup size="large" variant="text" aria-label="text button group">
          <StyledButtonGroup
            $active={searchObj.units === 'items'}
            onClick={() => unitsButtonClick(Units.Items)}>
            Штуки
          </StyledButtonGroup>
          <StyledButtonGroup
            $active={searchObj.units === 'price'}
            onClick={() => unitsButtonClick(Units.Price)}>
            Полочные рубли
          </StyledButtonGroup>
          <StyledButtonGroup
            $active={searchObj.units === 'incomingPrice'}
            onClick={() => unitsButtonClick(Units.IncomingPrice)}>
            Закупочные рубли
          </StyledButtonGroup>
        </ButtonGroup>
      </div>


      <LoadingButton
        variant="contained"
        color="primary"
        loadingPosition="start"
        sx={{ marginLeft: 'auto' }}
        startIcon={<ExcelIcon src={icon} alt="excelImg"/>}
        onClick={handleExport}
        size="small"
        loading={isLoading}>
        ЭКСПОРТ
      </LoadingButton>
    </StyledChartsHeader>
  )
}