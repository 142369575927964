export const unitsOptions = [
  {
    value: 'price',
    label: 'Полочные'
  },
  {
    value: 'incomingPrice',
    label: 'Закупочные'
  }
]

export const groupByDateOptions = [
  {
    value: 'day',
    label: 'дням'
  },
  {
    value: 'week',
    label: 'неделям'
  },
  {
    value: 'month',
    label: 'месяцам'
  },
  {
    value: 'quarter',
    label: 'кварталам'
  },
  {
    value: 'year',
    label: 'годам'
  }
]

export const statsOptions = [
  {
    value: 'orders',
    label: 'Заказы'
  },
  {
    value: 'sales',
    label: 'Продажи'
  },
  {
    value: 'stocks',
    label: 'Остатки'
  },
]

export const viewOptions = [
  {
    value: 'items',
    label: 'Штуки'
  },
  {
    value: 'currency',
    label: 'Рубли'
  },
  {
    value: 'all',
    label: 'Штуки и рубли'
  }
]