/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { AnalyticsDataOrder, AnalyticsUnits, SearchParams } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<AnalyticsDataOrder>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** @default "price" */
    units?: AnalyticsUnits,
  /**
   * Date From
   * Дата, начиная с которой возвращаются заказы
   * @format date
   */
    date_from?: string,
  /**
   * Date To
   * Дата, по которую возвращаются заказы
   * @format date
   */
    date_to: string,

},
  data: SearchParams
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.post<ResponseType>(`/analytics/orders`,data, { params: query }).then(res => res.data)


export const usegetOrdersOrdersPost = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

